import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loader from '../components/Loader';
import Error from '../components/Error';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import StripeCheckout from 'react-stripe-checkout';
import Swal from 'sweetalert2';
import AOS from 'aos';
import 'aos/dist/aos.css';
import emailjs from '@emailjs/browser';

AOS.init({
    duration: 2000
});

function Bookingscreen() {
    const [filled, setfilled] = useState(false);
    const [emailsent, setemailsent] = useState(false);
    const { roomid } = useParams();
    const { fromdate } = useParams();
    const { todate } = useParams();
    const [loading, setloading] = useState(true);
    const [error, seterror] = useState();
    const [room, setroom] = useState();
    const totaldays = (moment.duration((moment(todate, 'MM-DD-YYYY')).diff(moment(fromdate, 'MM-DD-YYYY'))).asDays());
    var [totalamount, settotalamount] = useState(0);
    const [wine, setwine] = useState(false);
    const [chocolate, setchocolate] = useState(false);
    const [flowers, setflowers] = useState(false);
    const [noneselected, setnoneselected] = useState(false);
    const [message, setmessage] = useState("");
    const [addedroses, setaddedroses] = useState("");
    const [addedchocolate, setaddedchocolate] = useState("");
    const [taxes, settaxes] = useState(0);
    const [salestax, setsales] = useState(0);
    const [IsActive, setIsActive] = useState(false);
    const [IsActiveTwo, setIsActiveTwo] = useState(false);
    const [IsActiveThree, setIsActiveThree] = useState(false); 
    const [firstname, setfirstname] = useState('');
    const [lastname, setlastname] = useState('');
    const [email, setemail] = useState('');
    const [phonenumber, setphonenumber] = useState('');
    const [address, setaddress] = useState('');
    const [selected, setselected] = useState(false)
    const [clearpackages, setclearpackages] = useState(false);

    useEffect(() => {

        let grandtotal = 0;
        const fetchSingle = async () => {
            try {
                setloading(true);
                const data = (await axios.post('/api/rooms/getroombyid', { roomid, fromdate, todate, totaldays })).data;
                localStorage.setItem('fromdate', fromdate);
                localStorage.setItem('todate', todate);
                let name = (data.name);

                if (name.includes("Joy")) {
                    let joytotal = JSON.parse(localStorage.getItem("Joy"));
                
                    joytotal ? grandtotal += joytotal : grandtotal = 0;

                    if (flowers) {
                        (grandtotal += 45.99)
                        localStorage.setitem('roses', "Dozen Roses : $45.99");
                        setflowers(flowers);
                    } if (wine) {
                        (grandtotal += 40.00);
                        localStorage.setitem('wine', "A bottle of wine : $40.00");
                        setwine(wine)
                    } if (chocolate) {
                        (grandtotal += 39.55);
                        localStorage.setitem('chocolate', "Chocolate Covered Strawberries : $39.55");
                        setchocolate(chocolate);
                    }
                    else {
                        settotalamount(0);
                        localStorage.setItem('total', 0);
                        setflowers(flowers);
                        setchocolate(chocolate);
                        setwine(wine)
                    }
                    let tax = (joytotal * 0.06);
                    let ocutax = tax.toFixed(2);
                    settaxes(ocutax);

                    let salestax = (joytotal * 0.0675);

                    let match = salestax.toFixed(2);

                    setsales(match);

                    grandtotal = grandtotal += tax += salestax
                    settotalamount(grandtotal.toFixed(2));
                    localStorage.setItem('total', grandtotal.toFixed(2));

                } if (name.includes("Faith")) {
                    const faithtotal = JSON.parse(localStorage.getItem("Faith"))

                    faithtotal ? grandtotal += faithtotal : grandtotal = 0;

                    if (flowers) {
                        (grandtotal += 45.99)
                        localStorage.setitem('roses', "Dozen Roses : $45.99");
                        setflowers(flowers);
                    } if (wine) {
                        (grandtotal += 40.00);
                        localStorage.setitem('wine', "A bottle of wine : $40.00");
                        setwine(wine)
                    } if (chocolate) {
                        (grandtotal += 39.55);
                        localStorage.setitem('chocolate', "Chocolate Covered Strawberries : $39.55");
                        setchocolate(chocolate);
                    }
                    else {
                        settotalamount(0);
                        localStorage.setItem('total', 0);
                        setflowers(flowers);
                        setchocolate(chocolate);
                        setwine(wine)
                    }
                    let tax = (faithtotal * 0.06);
                    let ocutax = tax.toFixed(2);
                    settaxes(ocutax);

                    let salestax = (faithtotal * 0.0675);
                    let match = salestax.toFixed(2);

                    setsales(match);

                    grandtotal = grandtotal += tax += salestax
                    settotalamount(grandtotal.toFixed(2));
                    localStorage.setItem('total', grandtotal.toFixed(2));

                } if (name.includes("Love")) {
                    const lovetotal = JSON.parse(localStorage.getItem("Love"))

                    lovetotal ? grandtotal += lovetotal : grandtotal = 0;
                    if (flowers) {
                        (grandtotal += 45.99)
                        localStorage.setitem('roses', "Dozen Roses : $45.99");
                        setflowers(flowers);
                    } if (wine) {
                        (grandtotal += 40.00);
                        localStorage.setitem('wine', "A bottle of wine : $40.00");
                        setwine(wine)
                    } if (chocolate) {
                        (grandtotal += 39.55);
                        localStorage.setitem('chocolate', "Chocolate Covered Strawberries : $39.55");
                        setchocolate(chocolate);
                    }
                    else {
                        settotalamount(0);
                        localStorage.setItem('total', 0);
                        setflowers(flowers);
                        setchocolate(chocolate);
                        setwine(wine)
                    }
                    let tax = (lovetotal * 0.06);
                    let ocutax = tax.toFixed(2);
                    settaxes(ocutax);

                    let salestax = (lovetotal * 0.0675);
                    let match = salestax.toFixed(2);

                    setsales(match);

                    grandtotal = grandtotal += tax += salestax
                    settotalamount(grandtotal.toFixed(2));
                    localStorage.setItem('total', grandtotal.toFixed(2));

                } if (name.includes("Hope")) {
                    const hopetotal = JSON.parse(localStorage.getItem("Hope"))

                    hopetotal ? grandtotal += hopetotal : grandtotal = 0;

                    if (flowers) {
                        (grandtotal += 45.99)
                        localStorage.setitem('roses', "Dozen Roses : $45.99");
                        setflowers(flowers);
                    } if (wine) {
                        (grandtotal += 40.00);
                        localStorage.setitem('wine', "A bottle of wine : $40.00");
                        setwine(wine)
                    } if (chocolate) {
                        (grandtotal += 39.55);
                        localStorage.setitem('chocolate', "Chocolate Covered Strawberries : $39.55");
                        setchocolate(chocolate);
                    }
                    else {
                        settotalamount(0);
                        localStorage.setItem('total', 0);
                        setflowers(flowers);
                        setchocolate(chocolate);
                   
                    }

                    let tax = (hopetotal * 0.06);
                    let ocutax = tax.toFixed(2);
                    settaxes(ocutax);

                    let salestax = (hopetotal * 0.0675);
                    let match = salestax.toFixed(2);

                    setsales(match);

                    grandtotal = grandtotal += tax += salestax
                    settotalamount(grandtotal.toFixed(2));
                    localStorage.setItem('total', grandtotal.toFixed(2));

                } if (name.includes("Grace")) {
                    const gracetotal = JSON.parse(localStorage.getItem("Grace"))

                    gracetotal ? grandtotal += gracetotal : grandtotal = 0;

                    if (flowers) {
                        (grandtotal += 45.99)
                        localStorage.setitem('roses', "Dozen Roses : $45.99");
                        setflowers(flowers);
                    } if (wine) {
                        (grandtotal += 40.00);
                        localStorage.setitem('wine', "A bottle of wine : $40.00");
                        setwine(wine)
                    } if (chocolate) {
                        (grandtotal += 39.55);
                        localStorage.setitem('chocolate', "Chocolate Covered Strawberries : $39.55");
                        setchocolate(chocolate);
                    }
                    else {
                        settotalamount(0);
                        localStorage.setItem('total', 0);
                        setflowers(flowers);
                        setchocolate(chocolate);
                        setwine(wine)
                    }
                    let tax = (gracetotal * 0.06);
                    let ocutax = tax.toFixed(2);
                    settaxes(ocutax);

                    let salestax = (gracetotal * 0.0675);
                    let match = salestax.toFixed(2);

                    setsales(match);

                    grandtotal = grandtotal += tax += salestax
                    settotalamount(grandtotal.toFixed(2));
                    localStorage.setItem('total', grandtotal.toFixed(2));

                } if (name.includes("Peace")) {
                    const peacetotal = JSON.parse(localStorage.getItem("Peace"))

                    peacetotal ? grandtotal += peacetotal : grandtotal = 0;

                    if (flowers) {
                        (grandtotal += 45.99)
                        localStorage.setitem('roses', "Dozen Roses : $45.99");
                        setflowers(flowers);
                    } if (wine) {
                        (grandtotal += 40.00);
                        localStorage.setitem('wine', "A bottle of wine : $40.00");
                        setwine(wine)
                    } if (chocolate) {
                        (grandtotal += 39.55);
                        localStorage.setitem('chocolate', "Chocolate Covered Strawberries : $39.55");
                        setchocolate(chocolate);
                    }
                    else {
                        settotalamount(0);
                        localStorage.setItem('total', 0);
                        setflowers(flowers);
                        setchocolate(chocolate);
                        setwine(wine)
                    }
                    let tax = (peacetotal * 0.06);
                    let ocutax = tax.toFixed(2);
                    settaxes(ocutax);

                    let salestax = (peacetotal * 0.0675);
                    let match = salestax.toFixed(2);

                    setsales(match);

                    grandtotal = grandtotal += tax += salestax
                    settotalamount(grandtotal.toFixed(2));
                    localStorage.setItem('total', grandtotal.toFixed(2));
                }
                setroom(data)
                localStorage.setItem("room", JSON.stringify(data));
                setloading(false);
            } catch (error) {
                seterror(true);
                setloading(false);
            }
        }


        fetchSingle().catch(console.error);
    }, [roomid, fromdate, todate, totaldays, totalamount, wine, flowers, chocolate]);

    function setnewamount(e) {

        if (e === 39.55) {

            IsActive ? setIsActive(false) : setIsActive(true);
            !IsActive ? setchocolate(true) : setchocolate(false);
          
            var total = totalamount += 39.55;
            total = parseFloat(total).toFixed(2);
            settotalamount(total);
            localStorage.setItem('total', total);
        }
        if (e === 45.99) {

            IsActiveTwo ? setIsActiveTwo(false) : setIsActiveTwo(true);
            !IsActiveTwo ? setflowers(true) : setflowers(false);
          
            var totaltwo = totalamount += 45.99;
            totaltwo = parseFloat(totaltwo).toFixed(2)
            settotalamount(totaltwo);
            localStorage.setItem('total', total);
        }
        if (e === 0.00) {

            IsActiveThree ? setIsActiveThree(false) : setIsActiveThree(true);
            !IsActiveThree ? setnoneselected(true) : setnoneselected(false);
            
            // var totalthree = totalamount += 40.00;
            // settotalamount(totalthree);
            // localStorage.setItem('total', total);
        } 
        else {
       
        }
  
   
}

    let flowerstwo = localStorage.getItem("flowers");
    let chocolatetwo = localStorage.getItem("chocolate");
    let winetwo = localStorage.getItem("wine");

    async function onToken(token) {

        let bookingDetails = {
            room,
            fromdate,
            todate,
            firstname,
            lastname,
            email,
            address,
            phonenumber,
            totalamount,
            totaldays,
            wine,
            chocolate,
            flowers,
            token
        }

        try {
            setloading(true);
            const result = await axios.post('/api/bookings/bookroom', bookingDetails);
            setloading(false);
            Swal.fire('Congratulations! You are booked!', "Thank you so much for booking with us! We can't wait to see you at the Cabin!", 'success').then(result => {
                window.location.href = '/signup'
              })
                // return result;
            return result;
        } catch (error) {
            setloading(false);
            Swal.fire('OOps', 'Something went wrong', 'error');
            return console.log(error);
        }
    }
    
    const sendEmail = (e) => {
        setfilled(true);
        setclearpackages(true)
        e.preventDefault();
        emailjs.sendForm('service_pkshh1d', 'template_ezsaj8t', e.target, 'MIDNfGnolFZme7E87');
        console.log("email sent");
        localStorage.clear();
      }

    function SubmitButton() {
       
        return <button type="submit" onClick={() => setselected(true)} style={{position: 'relative', alignContent: 'center', marginTop: '40px'}} className="btn btn-primary mt-12 section-title" >Okay! I've selected my additional packages.</button> 
    }

    return (

        <>

           <div>
           { !selected ?
           <div style={{ textAlign: 'center' }}>
                    <br/>
                             
                                
                            <h2>Additional Packages</h2>
                            <br/>
                            <h6>* Call the Cabin in advance to set up details on added packages</h6>
                            <button id="flowers" onClick={() => setnewamount(45.99)} style={{margin: '10px', backgroundColor: IsActiveTwo ? 'darkorange' : '' }}>A dozen roses: <b>$45.99</b>.</button>
                            <br/>
                            <button id="wine" style={{margin: '10px', backgroundColor: '' }}>* Coming Soon * A bottle of wine <b>$40.00</b>.</button>
                            <br/>
                            <button id="chocolate" onClick={() => setnewamount(39.55)} style={{margin: '10px', backgroundColor: IsActive ? 'darkorange' : '' }}>A box of delicious strawberries dipped in dark chocolate <b>$39.55</b>.</button>
                            <br/>
                            <button id="noneselected" onClick={() => setnewamount(0.00)} style={{margin: '10px', backgroundColor: IsActiveThree ? 'darkorange' : '' }}>Maybe Next Time</button>
                            <br/>
                            <br/>
                            <SubmitButton/>
                           
                        </div>
                   
                 : <></> }
        { selected ? 
      
    <div style={{width: '60%', textAlign: 'center', margin: 'auto'}} className="col justify-content-center mt-5"> 
          <div  className="justify-content-center" style={{textAlign : 'center'}}>
            <div style={{arginLeft: '50px', textAlign: 'center', alignContent: 'center'}}/>
            <h2>Please Provide Additional Details</h2>
       <br/>
          <form style={{textAlign: 'center'}} className="bs" onSubmit={sendEmail}>
            <label style={{color: 'orange', fontSize: '20px'}}htmlFor="roomname"> Room Name: </label>
            <input type="text" name="room_name" id="room_name" className="forminput" value={room.name}/>
            <br/>
            <label style={{color: 'orange', fontSize: '20px'}}htmlFor="fromdate">From Date:</label>
            <input type="text" name="from_date" id="from_date" className="forminput" value={fromdate}/>
            <br/>
            <label style={{color: 'orange', fontSize: '20px'}}htmlFor="todate">To Date:</label>
            <input type="text" name="to_date" id="to_date" className="forminput" value={todate}/>
            <br/>
            <label style={{color: 'orange', fontSize: '20px'}}htmlFor="todate">Room Occupansy Tax:</label>
            <input type="text" name="taxes" id="to_date" className="forminput" value={`$ ${taxes}`}/>
            <br/>
            <label style={{color: 'orange', fontSize: '20px'}}htmlFor="todate">Room Sales Tax:</label>
            <input type="text" name="salestax" id="to_date" className="forminput" value={`$ ${salestax}`}/>
            <label style={{color: 'orange', fontSize: '20px'}}htmlFor="total">Total:</label>
            <input type="text" name="total" id="total" className="forminput" value={`$ ${totalamount}`}/>
            <br/>
            <label style={{color: 'orange', fontSize: '20px'}}htmlFor="firstname">First Name:</label>
            <input type="text" onChange={e => setfirstname(e.target.value)} value={firstname} name="first_name" id="first_name" placeholder="Please provide first name" className="forminput"/>
            <br/>
            <label style={{color: 'orange', fontSize: '20px'}}htmlFor="lastname">Last Name:</label>
            <input type="text" onChange={e => setlastname(e.target.value)} value={lastname} name="last_name" id="last_name" placeholder="Please provide last name"className="forminput"/>
            <br/>
            <label style={{color: 'orange', fontSize: '20px'}}htmlFor="email">Email:</label>
            <input type="text" onChange={e => setemail(e.target.value)} value={email} name="email" id="email" placeholder="Please provide email"className="forminput" />
            <br/>
            <label style={{color: 'orange', fontSize: '20px'}}htmlFor="phonenumber">Phone Number:</label>
            <input type="text" onChange={e => setphonenumber(e.target.value)} value={phonenumber} name="phone_number" id="phone_number" placeholder="Please provide phone number"className="forminput" />
            <br/>
            <label style={{color: 'orange', fontSize: '20px'}}htmlFor="address">Address:</label>
            <input type="text" onChange={e => setaddress(e.target.value)} value={address} name="address" id="address" placeholder="Please provide address"className="forminput" />
            <br/>
            <br/>
            <label style={{color: 'orange', fontSize: '20px'}}htmlFor="message">Additional Info:</label>
            <input type="text" onChange={e => setmessage(e.target.value)} value={message} name="message" id="message" placeholder="Have a question or dietary preferences/allergies?"className="forminput" />

    
            <input type="text" onChange={() => setflowers(flowers)} name="roses" id="roses" className="forminput" value={flowers ? "A Dozen Exquisite Roses: $45.99" : "No Roses Added"}/>

            <input type="text" onChange={() => setchocolate(chocolate)} name="chocolate" id="chocolate" className="forminput" value={chocolate ? "A Dozen Delicious Chocolate Covered Strawberries: $39.55" : "No Strawberries Added"}/> 

            { firstname && lastname && email && address && totalamount > 0 ? <button type="submit" onClick={() => sendEmail} style={{position: 'relative', marginTop: '40px'}} className="btn btn-primary mt-12" >Information Verified</button> : "" }
            
          </form>
            </div>
          </div>
        : <></>
        } 
          </div>

                        <div className="m-5" data-aos="flip-left">
                        
            { filled && totalamount > 0 ? (
                
                <div className="row justify-content-center mt-5 bs">
<h2>Thank you! Please continue to payment.</h2>
<br/>
<br/>
<br/>
<div className="col-md-6">
    <h1>{room.name}</h1>
    <img src={room.imageurls[0]} className="bigimg" alt="bigimage" />
</div>

<div className="col-md-6">
    <div style={{ textAlign: 'right' }}>
        <h1>Booking Details</h1>
        <hr />

        <b>
            <h4>Your Booking</h4>
            <h5>From Date : {fromdate}</h5>
            <h5>To Date : {todate}</h5>            
        </b>
    </div>


    <div style={{ textAlign: 'left' }}>
        <b>
            <h1>Amount</h1>
            <hr />
            { wine ? (<h5>Wine: $40.00 </h5>) : (<h5>Wine: $0.00</h5>)}
            { chocolate ? (<h5>Dozen Chocolate Strawberries: $39.55</h5>) : (<h5>Dozen Chocolate Strawberries: $0.00</h5>)}
            { flowers ? (<h5>Dozen Roses: $45.99</h5>) : (<h5>Dozen Roses: $0.00</h5>) }
            <h5>Total nights : { totaldays.toFixed(0) } night stay</h5>
            <h5>Room Occupancy Tax: ${taxes} </h5>
            <h5>Room Sales Tax: ${salestax} </h5>
            <h5>Total Amount : ${totalamount}</h5>
            
            
        </b>
    </div>


    <div style={{ float: "right" }}>

        <StripeCheckout
            amount={totalamount * 100}
            token={onToken}
            currency={'USD'}
            // stripeKey="pk_live_51KWrgOEQ6YTqHunyjTvuPENEm4gD2k3W6z1hKXQqKpU1wVW88fyZ02eec3TTpdDyrHivYVdGVXbC1CKJk4776Fls00EQI9Kmbl"                    
            stripeKey="pk_test_51KWrgOEQ6YTqHunyX0uoWgUrVGqOACgCQPEcX947w2u602fVYWIlA6OWheyblRORUwhc47omYPsCrQNAaV7zAtXz00XZIzQEdg"                    
        >
            <div style={{paddingRight: '120px', paddingBottom: '30px'}}>
            <button className="btn btn-primary">Pay Now</button>
            </div>

        </StripeCheckout>
   

    </div>

</div>
</div>
                
            ) : (
                <h2 style={{textAlign:"center"}}>Please complete form to continue with payment.</h2>
            )
            }
        </div>
        </>
        
    )
}

export default Bookingscreen;
