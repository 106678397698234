import React, { useState, useEffect } from "react";
import TextSection from "../components/TextSection";
import axios from "axios";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import eventmenu from "../images/eventmenu.jpg";
import { Link } from "react-router-dom";
import Photos from "../components/Photos";
import "../components/Photos.css";

const Specialeventsscreen = () => {
  const [events, setevents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = (await axios.get("/api/events/getallevents")).data;
        setevents(data);
      } catch (error) {
        console.log(error);
      }
      setTimeout(() => {}, 1500);
    };
    fetchData().catch(console.error);
  }, []);

  const galleryImages = [
    {
      img: "https://i.postimg.cc/BZy2Nps9/event.jpg",
    },
    {
      img: "https://i.postimg.cc/nhPPK5T8/eventtwoo.jpg",
    },
    {
      img: "https://i.postimg.cc/N00QFWMW/eventthreee.jpg",
    },
  ];

  const pastryImages = [
    {
      img: "https://i.postimg.cc/Vkj9cHwG/cake1.jpg",
    },
    {
      img: "https://i.postimg.cc/0QN7DkwF/cake2.jpg",
    },
    {
      img: "https://i.postimg.cc/fWpYShTq/cake3.jpg",
    },
    {
      img: "https://i.postimg.cc/0ydS4MGh/cake4.jpg",
    },
  ];

  return (
    <>
      <br />
      <br />
      <br />
      <h1>Special Events & Celebrations</h1>
      <h1
        style={{
          textAlign: "center",
          verticalAlign: "middle",
          marginBottom: "4rem",
          color: "darkorange",
        }}
      >
        Check out our upcoming events!
      </h1>
      {events ? (
        events.map((event) => {
          var color = event.backgroundColor;
          let newcolor = color.toString();
          console.log(newcolor);
          return (
            <section
              stle={{
                fontSize: "1.5rem",
                color: "black",
                textAlign: "center",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                paddingTop: "0",
                paddingBottom: "0",
                marginLeft: "1rem",
                marginRight: "1rem",
                width: "50%",
                height: "auto",
              }}
            >
              <div
                className="demo"
                id="demo"
                style={{ backgroundColor: `${newcolor}` }}
              >
                {event.column === "right" ? (
                  <div className="container">
                    <div className="col-1">
                      <img
                        className="smallimgthree"
                        src={event.imageurl}
                        alt="menu"
                      />
                    </div>
                    <div className="col-1">
                      <br />
                      <p>{event.title}</p>
                      <p style={{ fontSize: "30px" }}>{event.description}</p>
                      <br />
                      {event.link ? (
                        <button
                          style={{ backgroundColor: "black" }}
                          className="btn-primary"
                        >
                          <a
                            style={{ color: "white", textDecoration: "none" }}
                            href={event.link}
                          >
                            Go to Facebook Event
                          </a>
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="container">
                    <div className="col-1">
                      <br />
                      <p>{event.title}</p>
                      <p style={{ fontSize: "30px" }}>{event.description}</p>
                      <br />
                      {event.link ? (
                        <button
                          style={{ backgroundColor: "black" }}
                          className="btn-primary"
                        >
                          <a
                            style={{ color: "white", textDecoration: "none" }}
                            href={event.link}
                          >
                            Go to Facebook Event
                          </a>
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-1">
                      <img
                        className="smallimgthree"
                        src={event.imageurl}
                        alt="menu"
                      />
                    </div>
                  </div>
                )}
              </div>
            </section>
          );
        })
      ) : (
        <></>
      )}
      <br />
      <br />
      <br />
      <h4 style={{ textAlign: "center", color: "darkorange" }}>
        It is a high honor to be able to host your celebration with your loved
        ones.
      </h4>
      <TextSection paragraph="Whether you are having a birthday party or a big celebration, let us host your event here! Little Heaven Bed & Breakfast is secluded so you can enjoy your party to the biggest. We have a conference room, a commercial kitchen with seating, outdoor space, a pond, and of course six guest rooms with six guest bathrooms. Check out our conference room below! Call the cabin to reserve your special day now." />
      <Photos galleryImages={galleryImages} />
      <br />
      <br />
      <h2
        style={{
          textAlign: "center",
          marginBottom: "4rem",
          color: "rgba(240, 116, 8, 0.848)",
        }}
      >
        Don't miss out on the fun! We look forward to seeing you at the Cabin.
        Thank you!
      </h2>
      <br />
      <br />
    </>
  );
};

export default Specialeventsscreen;
